.TopNav {
  background-color: var(--whiteBG);
  color: var(--whiteColorHead);
}
.TopNav a,
.TopNav button {
}

.TopNav a:hover,
.TopNav button:hover {
  color: var(--whiteColorHover);
  color: var(--whiteColorHover);
}

.TopNav svg {
  height: 1.1rem;
}
@media screen and (max-width: 600px) {
  .logout-btn {
    margin-left: 100px;
    margin-top: 42px;
    width: 100%;
    right: 0px;
    bottom: 2px;
  }
}
