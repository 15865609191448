html {
  min-height: 100%;
  height: 100%;
}
.App {
  /* grid-template-columns: repeat(1, 1fr); */
  /* grid-auto-rows: auto; */
  /* grid-auto-flow: row; */
}

.blueBG {
  background: var(--blueBG);
}

.App .vspacer {
  /* min-height: var(--min-space); */
  padding: 0;
}

.App > * {
  /* padding: var(--min-space) */
  /*   max(var(--min-space), calc((100% - var(--page-width)) / 2)); */
  /* width: 100%; */
  max-width: 100%;
}


input[type=date]{
  transition: all 2s;
}

input[type=date]:invalid{
  border-color:#b71c1c;
  color: #f44336;
  background-color:#ffcdd2 ;
}