:root {
  --blue: #264c9d;
  --gray: #d7dadf;
}
.pagination {
  margin: 25px auto 25px 25px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: var(--blue);
  border-color: var(--blue);
  color: #fff;
}
.pagination > li > a {
  border: 1px solid var(--gray);
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--gray);
  border-color: var(--gray);
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: darkgray;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
