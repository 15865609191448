@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants hover {
    .custom-hover-animation {
      border: 29px
        linear-gradient(
          135deg,
          var(--overlay-color-2) 0%,
          var(--overlay-color-1) 100%
        );
      opacity: 0.8;
    }

    .custom-hover-animation:hover::after,
    .custom-hover-animation:hover::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .custom-hover-animation:hover::before {
      color: white;
      background: linear-gradient(
        135deg,
        var(--overlay-color-2) 0%,
        var(--overlay-color-1) 100%
      );
      animation: OpacityAnim var(--anim-duration) ease-in-out 0s infinite
        alternate;
    }

    .custom-hover-animation:after {
      color: white;
      background: linear-gradient(
        135deg,
        var(--overlay-color-1) 0%,
        var(--overlay-color-2) 100%
      );
      animation: OpacityAnim var(--anim-duration) ease-in-out
        calc(-1 * var(--anim-duration)) infinite alternate;
    }
  }
}

html::-webkit-scrollbar {
  display: hidden;
}
html {
  background-color: #f3f4f6;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
:root {
  --overlay-color-1: #264c9d;
  --overlay-color-2: #778de6;
  --anim-duration: 2s;
}

@keyframes OpacityAnim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a,
a:hover,
a:visited {
  color: inherit;
}

* {
  box-sizing: border-box;
}

@supports (aspect-ratio: attr(width) / attr(height)) {
  img,
  input[type="image"],
  video,
  embed,
  iframe,
  marquee,
  object,
  table {
    aspect-ratio: attr(width) / attr(height);
  }
}

#root {
  /* min-height: 100vh; */
  /* background-image: url("https://static.wixstatic.com/media/5a19fa_2a1a2ff49d2345e49d40b4f1cf8469de~mv2_d_2048_1228_s_2.jpg"); */
  /* background-position: bottom center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}

:root {
  --page-width: 1240px;
  --min-space: 14px;

  --gray1: #242323;
  --gray2: #605e5e;
  --gray3: #2f2e2e;
  --gray4: #a0a09f;
  --blue: #0c4d9c;
  --bourdeaux: #891519;
  --white: white;
  --white-trans: rgba(255 255 255 / 88%);

  --grayscaleBGlight: var(--gray3);
  --grayscaleBGdark: var(--gray1);
  --grayscaleColor: var(--gray4);
  --grayscaleColorHover: var(--gray4);

  --blueBG: var(--blue);
  --blueBGHover: var(--bourdeaux);
  --blueColor: var(--white);

  --whiteBG: var(--white);
  --whiteBGTrans: var(--white-trans);
  --whiteColorHead: var(--gray3);
  --whiteColor: var(--gray2);
  --whiteColorHover: var(--blue);
}
