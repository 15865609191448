.contact {
  background-color: var(--whiteBG);
}

.contact svg {
  fill: var(--black);
  stroke: var(--blueColor);
  width: 1rem;
  height: 1rem;
}
