.dropdown > div[class*="menu"] {
  position: fixed;
  top: auto;
  left: auto;
  width: 288px;
}
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

.kudnenliste td:not(:last-child) {
  border-bottom: 0;
}

.kudnenliste th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 600px) {
  .dropdown {
    position: absolute;
    top: 3px;
  }
  tr {
    margin-bottom: 12px;
    width: 100%;
  }
  table thead {
    display: none;
  }
  table td {
    display: flex;
  }

  table td::before {
    content: attr(label);
    padding: 0 2px 0 2px;
    font-weight: bold;
    min-width: 100px;
  }
}
