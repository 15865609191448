.App-main {
  /* padding-top: 10px; */
  height: 80vh;
  background-color: var(--whiteBGTrans);
  color: var(--whiteColor);
  justify-content: center;
  /* align-items: flex-start; */
  align-content: center;
  flex-wrap: wrap;
}
