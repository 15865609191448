.App-header {
    color: var(--whiteColorHead);
    background: var(--whiteBG);
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.App-logo{
    height:60px;
    width: auto;
}