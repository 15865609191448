@media screen and (max-width: 600px) {
  .dropdown {
    position: absolute;
    top: 3px;
  }
  tr {
    margin-bottom: 12px;
    width: 100%;
  }
  table thead {
    display: none;
  }
  table td {
    display: flex;
  }

  table td::before {
    content: attr(label);
    padding: 0px 2px 0 2px;
    font-weight: bold;
    min-width: 100px;
  }
  table td::after {
    margin-bottom: 12px;
  }
}
